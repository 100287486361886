window.onload = function () {
	/*
	// If Safari ...
	if (navigator.userAgent.indexOf('Safari') !== -1
		&& navigator.userAgent.indexOf('Chrome') === -1) {
		var cookies = document.cookie;
		var href;

		// ... and if iframe ...
		if ((top.location !== document.location) && document.referrer) {
			// ... and if no cookie is set ...
			if (!cookies) {
				// ..., then set the parent location as the iframe location and add the parent href as get parameter "reref"
				href = document.location.href;
				href += (href.indexOf('?') === -1) ? '?' : '&';
				top.location.href = href + 'reref='
					+ encodeURIComponent(document.referrer);
			}
		} else {
			// ... add a cookie with the value ts=currentTime. Then take the GET parameter "reref" and set it as current location
			var ts = new Date().getTime();
			document.cookie = 'ts=' + ts;
			var rerefidx = document.location.href.indexOf('reref=');
			if (rerefidx !== -1) {
				href = decodeURIComponent(document.location.href
					.substr(rerefidx + 6));
				if (href)
				{
					window.location.href = href;
				}
			}
		}
	}
	*/

	if (window.nwxforms)
	{
		nwxforms(this);
	}

};

String.prototype.repeat = function (count) {
	if (count < 1) {
		return '';
	}
	var result = '', pattern = this.valueOf();
	while (count > 1) {
		if (count & 1) {
			result += pattern;
		}
		count >>= 1;
		pattern += pattern;
	}
	return result + pattern;
};

String.prototype.pad = function (width, padding) {
	padding = padding || '0';
	return this.length >= width ? this : padding.repeat(width - this.length) + this;
};

window.adjustBrightness = function (hex, steps) {
	// Steps should be between -255 and 255. Negative = darker, positive = lighter
	steps = Math.max(-255, Math.min(255, steps));

	// Normalize into a six character long hex string
	hex = hex.replace(/^#/, '');

	if (hex.length == 3) {
		hex = hex[0].repeat(2) + hex[1].repeat(2) + hex[2].repeat(2);
	}

	// Split into three parts: R, G and B
	var color_parts = hex.match(/../g);

	var ret = '#';

	color_parts.forEach(function (color) {
		color = parseInt(color, 16); // Convert to integer
		color = Math.max(0, Math.min(255, color + steps)); // Adjust color
		ret += color.toString(16).pad(2); // Make two char hex code
	});

	return ret;
};

window.getDocHeight = function(doc) {
	doc = doc || document;
	return $.documentHeight(doc);
};

$(function () {
	var input = document.createElement("input");
	if (('placeholder' in input) == false) {
		$('[placeholder]').focus(function () {
			var i = $(this);
			if (i.val() == i.attr('placeholder')) {
				i.val('').removeClass('placeholder');
				if (i.hasClass('password')) {
					i.removeClass('password');
					this.type = 'password';
				}
			}
		}).blur(function () {
			var i = $(this);
			if (i.val() == '' || i.val() == i.attr('placeholder')) {
				if (this.type == 'password') {
					i.addClass('password');
					this.type = 'text';
				}
				i.addClass('placeholder').val(i.attr('placeholder'));
			}
		}).blur().parents('form').submit(function () {
			$(this).find('[placeholder]').each(function () {
				var i = $(this);
				if (i.val() == i.attr('placeholder'))
					i.val('');
			})
		});
	}
});

Element.prototype.empty = function()
{
	while (this.lastChild)
	{
		this.removeChild(this.lastChild);
	}
};

// IE 10 throws an error while loading the config module
//if (!('dataset' in HTMLElement.prototype))
//{
//	Object.defineProperty(HTMLElement.prototype, "dataset",
//	{
//		get : function()
//		{
//			console.log('hhhh'+this);
//			return this.getAttribute('data-');
//		}
//	});
//}
